<template>
  <v-dialog v-model="show" scrollable max-width="800px">
    <v-card>
      <v-card-title>
        EDIT EMISSION FACTOR: {{ emissionFactor.name }}
      </v-card-title>
      <v-card-text class="pb-0">
        <v-checkbox
          v-model="form.enable"
          hide-details
          dense
          label="FE - Show this emission factor in the Front End app"
        ></v-checkbox>
        <v-checkbox
          v-model="form.aiEnable"
          hide-details
          dense
          label="AI - AI can use this emission factor for categorization"
        ></v-checkbox>

        <label class="d-block mt-4">Attached energy factor</label>
        <v-row dense>
          <v-col md="5" sm="6" cols="12">
            <v-autocomplete
              v-model="form.energyFactorId"
              :items="filteredEnergyFactors"
              item-text="activityName"
              item-value="id"
              placeholder="No energy factor selected"
              class="my-2"
              clearable
              hide-details
              hide-selected
              solo
              dense
              light
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="show = false">
          <v-icon class="black--text">mdi-close</v-icon>
          <p class="teal--text text--darken-4 mb-0">CLOSE</p>
        </v-btn>
        <v-btn :loading="loading" color="blue darken-1" text @click="submit">
          <v-icon class="black--text">mdi-content-save-outline</v-icon>
          <p class="teal--text text--darken-4 mb-0">SAVE</p>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { serialize } from "object-to-formdata";

export default {
  props: {
    value: Boolean,
    emissionFactor: Object,
    energyFactors: Array,
    averageDataUnitTypes: Array,
  },
  data() {
    return {
      loading: false,

      form: this.$inertia.form({
        id: null,
        enable: false,
        aiEnable: false,
        energyFactorId: null,
      }),
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    filteredEnergyFactors() {
      const normalizedAverageDataUnitTypes = this.averageDataUnitTypes.map(
        (x) => x.trim().toUpperCase()
      );

      const isAverageData = normalizedAverageDataUnitTypes.some(
        (x) => this.emissionFactor?.activityUnit?.trim().toUpperCase() === x
      );

      if (isAverageData) {
        return this.energyFactors.filter(
          (x) =>
            x.activityUnit?.trim().toUpperCase() ===
            this.emissionFactor?.activityUnit?.trim().toUpperCase()
        );
      } else {
        return this.energyFactors.filter(
          (x) =>
            !normalizedAverageDataUnitTypes.includes(
              x.activityUnit?.trim().toUpperCase()
            )
        );
      }
    },
  },
  methods: {
    setForm(emissionFactor) {
      if (!emissionFactor) {
        this.form.id = null;
        this.form.enable = true;
        this.form.aiEnable = true;
        this.form.energyFactorId = null;
      } else {
        this.form.id = emissionFactor.id;
        this.form.enable = emissionFactor.disabledAt === null;
        this.form.aiEnable = emissionFactor.aiDisabledAt === null;
        this.form.energyFactorId = emissionFactor.energyFactorId;
      }
    },
    submit() {
      if (!this.form.id) return;

      this.loading = true;

      this.form
        .transform((data) =>
          serialize({
            disable: !data.enable ? "true" : "false",
            aiDisable: !data.aiEnable ? "true" : "false",
            energyFactorId: data.energyFactorId,
          })
        )
        .put(this.route("emission-factors.update", this.form.id), {
          onSuccess: () => {
            this.show = false;
            this.setForm(null);
          },
          onFinish: () => {
            this.loading = false;
          },
        });
    },
  },
  watch: {
    emissionFactor: function (emissionFactor) {
      this.setForm(emissionFactor);
    },
  },
};
</script>
